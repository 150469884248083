import * as React from "react"
import { CalculateDiscount } from "../../functions/CalculateDiscount.js"
import { ProductContainer, ProductLink, Row, Col, WithoutDiscount, ColWhiteBG } from "./styles.js"
import ContentfulImage from "../../components/ContentfulImage/index.js"
import getSlug from "../../functions/getSlug.js"
import { getHumanReadableDuration } from "../../functions/getHumanReadableDuration.js"
import { CancelledNotice } from "../components/EventDate/index.js"

// Return a string similar to: '12 hours, 0 minutes' or "no length of activity provided"
const getLengthInTimeAsString = (node) => {
    // use the next sessions 'lengtInTime'
    if (!node.durationInMinutes) {
      return "no length of activity provided"
    }
  
    return getHumanReadableDuration(node.durationInMinutes)
}

export const ProductBoxStacked = ({ product, additionalJSX, cancelled }) => {
    // console.log('product [#dh7]: ', product)
    const productUrl = getSlug(product.urlSlug)
    console.log('productUrl [fjh5]: ', productUrl)
    const { finalRateAsString, withoutDiscountString } = CalculateDiscount(product.sellPrice, 13)

    // return 'sdfg'
    const description = (cancelled) ? (
        <>
            <CancelledNotice />
            &nbsp;This event will not run!
        </>
    ): product.descriptionShort
  
    return (
        <ProductLink to={productUrl}>
            <ProductContainer>
                <Row>
                    <Col className="col left" xs={12} md={12} lg={12}>
                        <ContentfulImage.CropIn image={product.featureImage} />
                    </Col>
                    <ColWhiteBG className="col middle padded" xs={12} md={12} lg={12}>
                        {additionalJSX}
                    <h3>
                        {product.name}
                    </h3>
                    {description}
                    <br />
                    {/* <span className="duration">Upcoming dates:</span>{" "}
                    todo. */}
                    </ColWhiteBG>
                    <Col
                        className="col right padded align-items-center"
                        xs={12} md={12} lg={12}
                    >
                        From:
                        <br />
                        <div className="price">
                            {finalRateAsString}
                            <WithoutDiscount>{withoutDiscountString}</WithoutDiscount>
                        </div>
                    </Col>
                </Row>
            </ProductContainer>
        </ProductLink>
    )
}