import { getFutureEventDates } from "../../components/ProductDetailed/getFutureEventDates"

// return products that are marked as "special" OR as having no future dates.
export const getSpecialsOrNoFutures = (products, limitCount) => {
    const specials = []
    let addedCount = 0
    products.map((curProduct) => {
      console.log("curItem #[fg6]: ", curProduct)
      const futureDates = getFutureEventDates(curProduct.eventDates)
      
      // console.log('addedCount: ', addedCount) 
      if (limitCount) {
        if(addedCount >= limitCount) { return }
      }
  
      console.group("name [am7]: " + curProduct.name)
      console.log("special: " + curProduct.special)
      console.log("futureDates.length: " + futureDates.length + " condition: " + !(futureDates.length > 0))
      console.log('final result: ' + ((curProduct.special == true) || !(futureDates.length > 0)))
      console.groupEnd()
      if ((curProduct.special == true) || !(futureDates.length > 0)) {
        // console.log("added special: " + curProduct.name + " #[hy7]: ", futureDates)  
        specials.push(curProduct)
        addedCount++
      }
    })
    
    console.log("future and specials #[rt5]: ", specials)
  
    return specials
}