class IncorrectNumberOfLocationsInSlug extends Error {
    constructor(maximumSlugLocations, count, startLocation, fullSlug) {
        const narrowestLocation = startLocation.title
        
        const message = 'The provided urlSlug content item should result in ' + maximumSlugLocations + ' location items in the url slug ' +
        '(for example: "Australia/Victoria/Melbourne"). Your urlSlug had: ' + count + ' location items. ' +
        'Please fix this in the CMS by editing the urlSlug content item.' +
        ' (urlSlug info: startLocation: "' + narrowestLocation + '", and contentful id: "' + startLocation.contentful_id + ', the resulting slug was: "' + fullSlug + '")'

      super(message);
    //   this.name = 'MyError';
    }
  }

module.exports = IncorrectNumberOfLocationsInSlug