import * as React from "react"

import { useStaticQuery, graphql } from "gatsby"
import { getSpecialsOrNoFutures } from "./functions/getSpecialsOrNoFutures"

// get the productList "Highlights"
export const useGetHighlightsProductList = (returnSpecialAndFutureDateOnly = false, limitCount) => {
  const data = useStaticQuery(graphql`
  query GetHighlightsProductList {
    allContentfulProductList(
      filter: {contentful_id: {eq: "2IWVssECX5Yuz2a64GL79F"}}
    ) {
      edges {
        node {
          name
          products {
            ... on ContentfulProduct {
              id
              name
              special
              descriptionShort
              sellPrice
              durationInMinutes
              eventDates {
                startDateTime
              }
              urlSlug {
                productName
                contentful_id
                startLocation {
                  contentful_id
                  parentLocation {
                    title
                    id
                    contentful_id
                    parentLocation {
                      contentful_id
                      id
                      title
                      parentLocation {
                        contentful_id
                        id
                        title
                      }
                    }
                  }
                  id
                  title
                }
              }
              featureImage {
                gatsbyImageData
                title
              }
            }
          }
          contentful_id
          id
        }
      }
    }
  }
  `)

  const highlightsProductsList = data.allContentfulProductList.edges[0].node.products
  console.log("highlightsProductsList [eu7]: ", highlightsProductsList)

  if (returnSpecialAndFutureDateOnly) {
    return getSpecialsOrNoFutures(highlightsProductsList, limitCount)
  }

  return highlightsProductsList
}