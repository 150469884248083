import React, { useContext, useState }  from "react";

import { FormErrors } from "../LoginForm/styles"

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { useForm } from '@formspree/react'

import { CurrentUserContext } from "../../../context/CurrentUserProvider"
import { DiscountContext } from "../../../context/DiscountProvider";
import { generateRandomString } from "../_shared/generateRandomString";
import { triggerGAFormSubmissionEvent } from "../_shared/triggerGAFormSubmissionEvent";

// #[mw1]
const LERegistrationForm = () => {
  const formId = 'LERegistration'
  const { processUserResponseData } = useContext(CurrentUserContext)
  let { sourceCode } = useContext(DiscountContext)
  
    // dummy values (if in dev mode)
    console.log('process.env.NODE_ENV: ', process.env.NODE_ENV)
  const devMode = (process.env.NODE_ENV == 'development')

  // Generate a random string of 5 characters
  const randomString = generateRandomString(5);

  const fnameInitial = (devMode) ?    'bettye' : '';
  const emailInitial = (devMode) ?    'bettye.fritsch_TEST_' + randomString + '@gmail.com' : '';
  const countryOfOriginInitial = (devMode) ?  'japan' : '';
  const targetLangInitial = (devMode) ?  'english' : '';

  const [fname, setFname]                       = useState(fnameInitial);
  const [email, setEmail]                       = useState(emailInitial);
  const [countryOfOrigin, setCountryOfOrigin]   = useState(countryOfOriginInitial);
  const [targetLang, setTargetLang]             = useState(targetLangInitial);

  const [fnameWarning, setFnameWarning]             = useState("");
  const [emailWarning, setEmailWarning]             = useState("");
  const [countryWarning, setCountryWarning]         = useState("");
  const [targetLangWarning, setTargetLangWarning]   = useState("");
  
  const [errorMsg, setErrorMsg] = useState("");

  const [formData2, setFormData2] = useState({
    name: '',
    email: '',
    message: '',
  });

  // setup formSpree submission hook
  const [state, FormSpreeSubmit, reset] = useForm('mgegoewp');

  let receivedMsg = ''
  if (state.succeeded) {
    receivedMsg = (
      <div>
        <br /><b>Registration received.</b>
        <br />We have sent you a confirmation email to the email address you provided.
      </div>
    )
  }

  function validateForm() {
    return fname.length > 0 && email.length > 0 && countryOfOrigin.length > 0 && targetLang.length
  }
  
  const handleChange = (e) => {
    setFormData2({ ...formData2, [e.target.name]: e.target.value });
  }

  const handleSubmit = (event) => {
    event.preventDefault()


    setFnameWarning((!fname.length > 0 ) ? "Error: name field cannot be empty" : "");
    setEmailWarning((!email.length > 0 ) ? "Error: email address cannot be empty" : "");
    setCountryWarning((!countryOfOrigin.length > 0 ) ? "Error: country of origin cannot be empty" : "");
    setTargetLangWarning((!targetLang.length > 0 ) ? "Error: target Language cannot be empty" : "");
    console.group('form submit: ')
    console.log('scd: ', sourceCode)

    // fname, setFname]                       = useState(fnameInitial);
  // const [email, setEmail]                       = useState(emailInitial);
  // const [countryOfOrigin, setCountryOfOrigin]   = useState(countryOfOriginInitial);
  // const [targetLang, setTargetLang]             = useState(targetLangInitial);
    console.groupEnd()

    
    if (!validateForm()) {
      setErrorMsg('The form has errors')
    } else {
      FormSpreeSubmit(event)
      triggerGAFormSubmissionEvent(formId)
      // triggerGAEvent('form_submission', 'form_submit', 'LE membership registration', 1)
      // category, action, label, value
    }
  }

  return (
    <>
        <div className="">
        {errorMsg && (
          <FormErrors>
            {errorMsg}
          </FormErrors>
          )
        }
        
        <div style={{display: 'flex', justifyContent:'center', alignItems:'center'}}>
          <div style={{width:'600px'}}>
            <h2>Language exchange signup form:</h2>

            This language exchange is run by New To Melbourne. If you are attending, the entry is free if you register (via the form below).
            
            <br /><br />Please note: entry to the event *without* completing the membership form is $5 per person.

            {receivedMsg}
            
            {!receivedMsg && 
              <>
                <br /><br />
                <Form onSubmit={handleSubmit} id={formId}>
                  <Form.Group size="lg" controlId="fname">
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                      autoFocus
                      type="text"
                      value={fname}
                      name="firstName"
                      onChange={(e) => setFname(e.target.value)}
                    />
                    <div>
                      {fnameWarning}
                    </div>
                  </Form.Group>
                  <Form.Group size="lg" controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      autoFocus 
                      type="email"
                      value={email}
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <div>
                      {emailWarning}
                    </div>
                  </Form.Group>
                  <Form.Group size="lg" controlId="targetLang">
                    <Form.Label>Target language (you want to learn)</Form.Label>
                    <Form.Control
                      type="text"
                      value={targetLang}
                      name="targetLang"
                      onChange={(e) => setTargetLang(e.target.value)}
                    />
                    <div>
                      {targetLangWarning}
                    </div>
                  </Form.Group>
                  <Form.Group size="lg" controlId="countryOfOrigin">
                    <Form.Label>Country (you are from)</Form.Label>
                    <Form.Control
                      type="text"
                      value={countryOfOrigin}
                      name="countryOfOrigin"
                      onChange={(e) => setCountryOfOrigin(e.target.value)}
                    />
                    <div>
                      {countryWarning}
                    </div>
                  </Form.Group>
                  <Form.Control
                    type="hidden"
                    value={sourceCode}
                    name="scd"
                    autocomplete="off"
                  />
                  <Form.Control
                    type="hidden"
                    value={'NTM.org/FreeMembershipRegistration'}
                    name="pc"
                  />
                  <Form.Control
                    type="hidden"
                    value={'LE'}
                    name="eventType"
                  />
                  <Button block size="lg" type="submit" disabled={!validateForm()}>
                    Register (for FREE)
                  </Button>
                </Form>
              </>
            }
          </div>
        
        </div>
      </div>
    </>
  )
}

export default LERegistrationForm