import * as React from "react"

import { useStaticQuery, graphql } from "gatsby"

// return all products in the productList with id: x
export const useGetHomepageProductsList = () => {
  const data = useStaticQuery(graphql`
  query GetHomepageProductsList {
    allContentfulProductList(
      filter: {contentful_id: {eq: "3u3dEjdBFzTkudIzN7pECx"}}
    ) {
      edges {
        node {
          name
          products {
            ... on ContentfulProduct {
              id
              name
              descriptionShort
              sellPrice
              durationInMinutes
              urlSlug {
                productName
                contentful_id
                startLocation {
                  contentful_id
                  parentLocation {
                    title
                    id
                    contentful_id
                    parentLocation {
                      contentful_id
                      id
                      title
                      parentLocation {
                        contentful_id
                        id
                        title
                      }
                    }
                  }
                  id
                  title
                }
              }
              featureImage {
                gatsbyImageData
                title
              }
            }
          }
          contentful_id
          id
        }
      }
    }
  }
  `)
  const homepageProductsList = data.allContentfulProductList.edges[0].node.products

  return homepageProductsList
  
}