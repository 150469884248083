import styled from "styled-components"
import Container from 'react-bootstrap/Container'

import { default as OrgRow } from "react-bootstrap/Row"
import { default as OrgCol } from "react-bootstrap/Col"
import { Link } from "gatsby";


export const WithoutDiscount = styled.div`
    font-size:          .6em;
    text-decoration:    line-through;
    font-weioght:       normal;
    color:              #77c0ff;
`

export const ProductLink = styled(Link)`
    :hover {
        text-decoration: none !important;
    }
`

export const Row = styled(OrgRow)`
    margin-right: 0px;
    margin-left: 0px;
`

export const Col = styled(OrgCol)`
    padding-bottom: 0px;
`

export const ProductContainer = styled(Container)`
    // padding-top: 10px;
    width: 100%;
    margin: 5px 0px 5px 0px;
    padding: 0px;
    border-radius: 5px;
    color: #333;

    border: 3px solid #ccc;

    .padded {
        // padding: 5px 0px;
    }

    .left {
        padding: 0px;
    }

    .middle {
        // font-size:
        padding-left: 10px;
        padding-right: 10px;
        h3 {
            color: #2d6dce;
        }
    }

    .right {
        padding-left: 12px;
        padding-right: 3px;
        background-color: #2693f1;
        color: #fff;
    }
    
    .price {
        margin-bottom:  px;
        font-size:      20px;
        font-weight:    bold;
        // color: #222;
    }

    .duration {
        font-weight: bold;
    }

    :hover {
        border: 3px solid rgb(26 108 197);
        text-decoration: none !important;
    }
`

export const ColWhiteBG = styled(OrgCol)`
    background-color: white;
`