import * as React from "react"

import { ListContainer, Row } from "./styles.js"
import "bootstrap/dist/css/bootstrap-grid.min.css"
import { useGetHomepageProductsList } from "../../hooks/useGetHomepageProductsListX.js"
import { ProductCardsList } from "../../_shared/ProductCardsList/index.js"
import { useGetHighlightsProductList } from "../../hooks/useGetHightlightsProductsList.js"

// Display a list of all products - likely for use on the homepage.
export const ProductSampler = ({ children, productListName }) => {
  console.log("productListName [kn7]: ", productListName)
  const homepageProducts = useGetHomepageProductsList()
  const highlightsProducts = useGetHighlightsProductList(true, 3)

  let products = null
  switch (productListName) {
    case 'homepage':
      products = homepageProducts
      break
    case 'highlights':
      products = highlightsProducts
      break
    default:
      throw new Error('productListName of value: "' + productListName + '" not recognised.')
      break
  }

  {console.log("[#fh34] products: ", products )}
  return (
    <ListContainer>
      <Row>
        {children}
      </Row>
      <Row>
        <ProductCardsList products={products} />
      </Row>
    </ListContainer>
  )
}

export default ProductSampler