const IncorrectNumberOfLocationsInSlug = require('../Errors/IncorrectNumberOfLocationsInSlug')
// return something like: "Australia/Victoria/Melbourne/1-day-great-ocean-road-bus-trip" by looking over urlLocation > startLocation
// (where "Melbourne" is the start location.)

// for use in generating the full URL slug
// note: the "Melbourne" part indicates where the experiences starts from, however this could be a tour to GOR (for instance).
const getSlug = (urlSlug) => {
    // console.log ('=== urlSlug: ', urlSlug)

    // replace() will turn ' ' into '-'
    const slugProductNameFragment = encodeURIComponent(urlSlug.productName.replace(/\s/g, "-"))
    
    const fullSlug = getSlugFragment(urlSlug.startLocation) + '/' + slugProductNameFragment
    checkNumberOfLocations(urlSlug, fullSlug)
    // console.log ('=== fullSlug: ', fullSlug)
    // console.log ('=== urlSlug: ', urlSlug)

    // add a slash, so it's not relative (to the current page being browsed)
    return '/' + fullSlug
}

// checks that the number of locations is correct (probably 3, e.g. "Australia/Victoria/Melbourne")
// throws an error if it's not = 3
const checkNumberOfLocations = (urlSlug, fullSlug) => {
    const startLocation = urlSlug.startLocation
    const count = countLocationsRecursively(startLocation)
    // console.log('==== checkNumberOfLocations(). Count = ' + count)

    const maximumSlugLocations = 3
    
    // console.log ('location: ', location)
    if (count !== maximumSlugLocations) {
        throw new IncorrectNumberOfLocationsInSlug(maximumSlugLocations, count, startLocation, fullSlug)
    }

    return true
}

// check if a parent location exists and return a count of how many exist (including original child location)
const countLocationsRecursively = (location, count = 0) => {
    // console.log ('==== location (count): ', location)
    count = count + 1
    
    // if a parent location exists, call this function recusively (and add one to count)
    if (location.parentLocation) {
        // console.log('==== location.parentLocation: ', location.parentLocation)
        count = countLocationsRecursively(location.parentLocation, count)
        return count
    }

    return count
}

// add the next fragment (e.g. Australia, Victoria, Melbourne etc to the slug.) recursively.
const getSlugFragment = (location) => {

    // replace() removes all ' ' (space bar characters)
    var slugFragment = encodeURIComponent(location.title.replace(/ /g, ''))
    
    // console.log('slug location fragment: ' + slugFragment)
    if (location.parentLocation) {
        // slugFragment = encodeURIComponent(getSlugFragment(location.parentLocation, count++)) + '/' + slugFragment
        slugFragment = getSlugFragment(location.parentLocation) + '/' + slugFragment
    }

    return slugFragment
}

module.exports = getSlug