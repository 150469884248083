import React  from "react"

import Layout from "../components/Layout";
import LERegistrationForm from "../components/forms/LEregistrationForm";
import ProductSampler from "../components/ProductSampler";

// language exchange "FREE ENTRY" signup form (that connects to mailChimp)
const IndexPage = () => {

  return (
    <Layout>
      <LERegistrationForm />
      <h3>New To Melbourne! events and sightseeing tours:</h3>
      <ProductSampler productListName="homepage" />
    </Layout>
  )
}

export default IndexPage;