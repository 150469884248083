import * as React from "react"
import { ProductBoxStacked } from "../ProductBoxStacked"
import { Col } from "./styles.js"

// display a list of product "cards" (i.e. they appear vertical)
export const ProductCardsList = ({ products }) => {
    return (
        <>
            {products.map((node) => {
                console.log("product #123343: ", node)
                
                return (
                    <Col key={node.id} xs={12} md={6} lg={4}>
                        <ProductBoxStacked product={node} />
                    </Col>
                )
            })}
        </>
    )
}